import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import ContentMDX from "../components/ContentMDX";
import Layout from "../components/Layout";
import KeyboardKey from "../components/KeyboardKey";

const GameFrame = styled.iframe`
  flex-grow: 1;
  max-width: 100vw;
  max-height: 100vh;
  width: 800px;
  height: 600px;

  @media screen and (min-width: 640px) and (min-height: 600px) {
    width: 640px;
    height: 480px;
  }
  @media screen and (min-width: 1280px) and (min-height: 768px) {
    max-width: 800px;
    max-height: 600px;
    
  }

  @media screen and (min-width: 1450px) and (min-height: 850px) {
    max-width: 1024px;
    max-height: 768px;
    
  }

  @media screen and (min-width: 2050px) and (min-height: 850px) {
    width: 1280px;
  }
`;

const ControlsList = styled.ul`
    list-style-type: none;
    padding-inline-start: 0;
`
const ListItem = styled.li`
  margin: 10px 0;
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;
const Grow = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const LayoutGame = styled(Layout)`
  display: flex;
  flex-direction: column;
`

const PlatformerGameMDX = ({ data: { page } }) => {
  return (
    <LayoutGame {...page.frontmatter}>
                  <Flex>
              <Grow>
              <GameFrame src="https://game.stevebullis.com" />
              </Grow>
            </Flex>
      
      <div>
        <h3>Controls</h3>
        <ControlsList>
          <ListItem>
            <KeyboardKey>⬆️</KeyboardKey> x 2 - Double Jump
          </ListItem>
          <ListItem>
            <KeyboardKey>⬆️</KeyboardKey>
            <KeyboardKey>⬅️</KeyboardKey> <KeyboardKey>➡️</KeyboardKey>
            <KeyboardKey>⬆️</KeyboardKey> Directional Controls
          </ListItem>
        </ControlsList>
      </div>
      <ContentMDX source={page.body} />
    </LayoutGame>
  );
};

export default PlatformerGameMDX;

export const pageQuery = graphql`
  query PlatformerGameMDX($id: String!) {
    page: mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        subtitle
        profile
      }
    }
  }
`;
