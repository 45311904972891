import React from "react";
import styled from "styled-components";
const KeyboardKey = styled.kbd`
  color: #fff;
  margin: 0 5px;
  padding: 1px 5px;
  font-size: 1.2em;
  font-family: courier new;
  border: 1px #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, #000),
    color-stop(25%, #111) /* Black Keyboard Key Style by Showeblogin */
  );
  background: -o-linear-gradient(left, #000 0%, #111 25%);
  background: -moz-linear-gradient(left, #000 0%, #111 25%);
  background: -webkit-linear-gradient(left, #000 0%, #111 25%);
  background: -ms-linear-gradient(left, #000 0%, #111 25%);
  background: linear-gradient(left, #000 0%, #111 25%);
  -webkit-box-shadow: 1px 0 1px 0 #000, 0 2px 0 2px lightGray, 0 2px 0 3px #333;
  -moz-box-shadow: 1px 0 1px 0 #000, 0 2px 0 2px lightGray, 0 2px 0 3px #333;
  box-shadow: 1px 0 1px 0 #000, 0 2px 0 2px lightGray, 0 2px 0 3px #333;
  /* Black Keyboard Key Style by www.superwebtricks.com */

  @:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

export default ({children})=><KeyboardKey>{children}</KeyboardKey>